/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
}

h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection, span::selection, p::selection, strong::selection, li::selection, div::selection {
    background-color: #ffd100;
    color: black;
  }
  
h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection, span::-moz-selection, p::-moz-selection, strong::-moz-selection, li::-moz-selection, div::-moz-selection {
    background-color: #ffd100;;
    color: black;
  }

.lazyLoading{
    background-image: linear-gradient(to bottom right, rgb(131, 131, 131), black);
    height: 100vh;
}

/* Fonts */
.Montserrat {
    font-family: "Montserrat", sans-serif;
}

/* App  */
.App {
    /* background-image: linear-gradient(to bottom right, rgb(91, 91, 91), black); */
    /* background-color: black; */
    font-family: "Montserrat", sans-serif;
}

/* Navbar */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21%;
    width: 100%;
    padding: 0 3%;
    position: fixed;
    z-index: 1;
    background-color: transparent;
    transition: all 0.7s;
    font-size: 1.8em;
}

.background {
    transition: all 0.7s;
    background-color: rgba(35, 35, 35, 0.9);
    height: 13%;
    font-size: 1.3em;
}

.logo-div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo-div > img {
    height: 60%;
    border-radius: 10%;
    margin-right: 10px;
}

.logo-title {
    color: white;
    font-weight: bold;
}

.logo-title span {
    color: #ffd100;
}

.nav > ul {
    font-size: 0.7em;
    display: flex;
    gap: 35px;
    list-style: none;
    color: white;
}

.link {
    color: white;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
}

.link:hover{
    color: #ffd100;
}

.activeLink {
    border-bottom: 1px solid #ffd100;
    padding-bottom: 1px;
    color: #ffd100;
}

@keyframes menu-slide-up-fade-in {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    } 
}

.navbar-services{
    animation: menu-slide-up-fade-in 0.6s ease-out forwards;
}

.navbar-services li {
    padding: 12px 0;
    list-style: none;
    text-align: center;
    border-bottom: 1px solid rgb(99, 99, 99);
}

.navbar-services a:last-child li{
    border-bottom: none;
}

.navbar-services li:hover {
    background-color: #9b8013;
}

.navbar-services .hashLink {
    text-decoration: none;
    color: white;
    font-family: inherit;
}

#red-team {
    border-radius: 10px 10px 0px 0px;
}

#purple-team {
    border-radius: 0px 0px 10px 10px;
}

.nav > ul > li {
    font-family: "Montserrat", sans-serif;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.services-item-details .services-item-details-icon{
    transform: rotate(180deg);
}

.services-item-details-icon{
    transition: transform 0.3s ease-out;
}

.nav a {
    cursor: pointer;
}

.nav-img {
    height: 70%;
}

.burger-icon-open {
    display: none;
}

.burger-menu {
    display: none;
}

.hidden-burger-menu {
    display: none;
}

.landingPage {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    background-image: linear-gradient(to bottom right, rgb(91, 91, 91), black);
    background: url("./assets/LandingPage/services/landingPage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.landingPage::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
        ellipse at center,
        transparent,
        rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
}

.landingPage strong {
    color: white;
    font-size: 2.5em;
    text-align: center;
}

.contact-us-btn {
    font-family: "Montserrat", sans-serif;
    background: #ffd100;
    padding: 15px 40px;
    border-radius: 10px;
    color: white;
    margin-top: 4%;
    font-size: 1.5em;
    border-width: 0;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.contact-us-btn:hover {
    /* create a bouncing animation */
    scale: 1.1;
}

/* Services */
.services {
    padding: 150px 2% 80px 4%;
    min-height: 100vh;
}

.intro {
    margin-top: 30;
    width: 80%;
    color: #7a7a7a;
    line-height: 1.5;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
}

.services-container img {
    width: 80%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}

.services-container  a {
    flex: 1 0 30%;
    /* margin: 1em; */
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.services-container img:hover {
    transform: scale(1.2);
}

.services-container h2 {
    font-size: 1.5em;
    margin: 1em 0;
}

.services-container p {
    font-size: 1;
    margin: 1em 0em;
    color: #7a7a7a;
    line-height: 1.5;
    font-size: medium;
}

.mobile-accordion {
    display: none;
}

.our-services{
    display: none;
}

@keyframes slide-up-fade-in {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    } 
}

.our-services-animation {
    animation: slide-up-fade-in 1.3s ease-out forwards;
}

/* Service */
.service-container > div:first-of-type {
    padding-top: 170px;
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom right, rgb(91, 91, 91), black);
    padding: 130px 100px;
}

.service img {
    width: 50vw;
}

.service h1 {
    color: white;
    font-size: 2.5em;
    margin: 50px 0;
}

.service p {
    color: white;
    font-size: 1.4em;
    margin: 30px 0;
}

/* background linear */
.testimonial-steps {
    background-image: linear-gradient(to bottom right, rgb(131, 131, 131), black);
    padding-top: 70px;
}

/* Our Mission */
.responsive-list {
    list-style: none;
    padding: 30px;
    margin: 0;
    display: flex;
    color: white;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.responsive-list li {
    margin: 10px 0;
    font-size: 1.1em;
    text-align: center;
    padding: 30px 3%;
    border: 1px solid white;
    box-shadow: 0 0 10px rgba(255, 209, 0, 0.8);
    border-radius: 10px;
    flex: 1 0 calc(25% - 40px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider{
    width: 95%;
    margin: 0 auto;
    color: white;
    text-align: center;
    border-bottom: 3px solid white;
}

.slider h2 {
    width: 50%;
    margin: auto;
    margin-top: 30px;
}

.slider > div{
    margin: 80px 0px 120px 0px;
}

.our-clients{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    /* width: 100%; */
    height: 300px;
}

.our-clients img{
    width: 80%;
    height: 90%;
    border-radius: 10px;
    flex: 1 0 calc(25% - 40px);
}

/* Steps */
.steps {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
}

.steps h1 {
    color: white;
    text-align: center;
    margin-bottom: 80px;
}

.three-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    width: 100%;
}

.step {
    background-color: white;
    border-radius: 10px;
    width: 27%;
    text-align: center;
    padding: 25px 10px 25px 10px;
    font-size: 1.5em;
    min-height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step h4 {
    color: #ffd100;
    margin-top: 25px;
    margin-bottom: 10px;
}

.step h5 {
    width: 90%;
    margin: auto;
}

.check-button {
    background-color: #ffd100;
    border-radius: 20px;
    padding: 8px 35px 8px 35px;
    border: 0;
    color: white;
    font-weight: bolder;
    margin-top: 15px;
    font-family: "Montserrat", sans-serif;
}

/* About Us Page */
.our-story {
    padding: 170px 0 50px 0;
    text-align: center;
}

.our-story p {
    color: white;
    text-align: center;
    font-size: 3.5em;
    margin-top: 100px;
}

.our-story img {
    width: 90vw;
    height: 80vh;
    object-fit: cover;
}

.our-value {
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
    text-align: center;
}

.our-value h3 {
    padding: 30px 10%;
}

.our-value p {
    padding-top: 30px;
}

.our-value img {
    width: 80vw;
}


.our-vision {
    padding-bottom: 100px;
}

.our-vision h3 {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 60px;
    color: white;
}

/* Contact Us Page */
.contact-us-screen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10%;
    align-items: center;
    background: url("./assets/ContactUs/meeting1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.contact-us-screen p {
    color: white;
    font-size: 2em;
    text-align: center;
    line-height: 1.8;
}

.contact-us-info {
    background-image: linear-gradient(to bottom right, rgb(91, 91, 91), black);
    padding: 80px 0px;
    color: white;
    text-align: center;
}

.contact-us-info-details {
    display: flex;
    gap: 20px;
    padding: 80px 50px;
    text-align: center;
    justify-content: space-around;
}

.contact-us-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
}

.contact-us-item p:nth-child(3) {
    font-size: 1.3em;
    color: rgb(142, 142, 142);
}

.contact-us-item span {
    color: #ffd100;
    font-size: 1.3em;
}

/* Footer */
.footer {
    height: 70px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
}

.footer div {
    color: #ffd100;
    flex: 1;
}

.footer ul:nth-child(2) {
    display: flex;
    color: #ffd100;
    list-style: none;
    gap: 25px;
    flex: 1;
    justify-content: center;
}

.footer ul:nth-child(3) {
    display: flex;
    list-style: none;
    gap: 15px;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.footer li {
    cursor: pointer;
}

.footer li:hover {
    color: #fffefa;
}

.whatsapp {
    display: none;
}

/* Media query */
@media (width <= 1024px) {
    /* Navbar */
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: 100%;
        padding: 0 10px;
        position: fixed;
        z-index: 1;
        background-color: transparent;
        transition: all 0.7s;
        font-size: 1.1em;
    }

    .background {
        transition: all 0.7s;
        background-color: rgba(35, 35, 35, 0.9);
        height: 70px;
        /* font-size: 0.9em; */
    }

    .logo-div {
        min-width: 10px;
        padding-left: 0;
    }

    .logo-div-short {
        transform: scale(0.8, 0.8);
        padding-left: 0;
        margin-left: -20px;
        /* gap: 5px; */
    }

    .logo-div > img {
        height: 65px;
    }

    .nav > ul {
        display: none;
    }

    .burger-icon-open {
        display: block;
    }

    .burger-icon-open:active {
        background-color: rgba(30, 30, 30, 0.5);
    }

    .burger-icon-close:active {
        background-color: rgba(50, 50, 50, 0.5);
    }

    .burger-menu {
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #ffd100;
        width: 100vw;
        height: 100vh;
        transition: right 0.7s;
        z-index: 100;
    }

    .hidden-burger-menu {
        display: block;
        position: absolute;
        right: -100%;
        top: 0px;
        background-color: #ffd100;
        width: 100%;
        height: 1000px;
        transition: right 0.7s;
    }

    .burger-menu > ul {
        font-size: 25px;
        padding-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        color: white;
        list-style: none;
    }

    .hidden-burger-menu > ul {
        font-size: 25px;
        padding-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        color: white;
        list-style: none;
    }

    /* Landing Page */

    .landingPage {
        background-image: linear-gradient(to bottom right, rgb(91, 91, 91), black);
        background: url("./assets/LandingPage/services/landingPage.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        display: flex;
        gap: 30px;
        padding-bottom: 20%;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        font-size: 1.1em;
        background-position: 88% center
    }

    .landingPage strong {
        color: white;
        font-size: 1.5em;
        text-align: center;
    }

    .contact-us {
        margin-top: 40px;
        padding: 8px 30px;
        font-size: 1.1em;
    }

    /* Services */
    .services {
        display: block;
        padding-top: 15%;
    }

    @keyframes slide-up-fade-in {
        0% {
            transform: translateY(100px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        } 
    }
    
    .our-services-animation {
        animation: slide-up-fade-in 1s ease-out forwards;
    }
    
    .services img{
        width: 85vw
    }

    .intro {
        width: 90%;
    }

    .company-info > img {
        height: 100px;
    }

    .services-container > a {
        margin: 0em;
        flex: 1 0 100%;
    }

    .mobile-accordion {
        display: block;
        margin-top: 20px;
    }

    .mobile-accordion h2 {
        color: rgb(200, 200, 200);
        margin: 10px 0 15px 0;
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 20px;
    }

    /* Our Mission */
    .responsive-list {
        justify-content: space-around;
    }

    .responsive-list li {
        flex: 1 0 70vw;
        padding: 30px 10%;
    }

    /* Steps */
    .steps {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .steps h1 {
        font-size: 1em;
        margin-bottom: 30px;
    }

    .three-steps {
        flex-direction: column;
        gap: 35px;
    }

    .step {
        background-color: white;
        border-radius: 10px;
        width: 70%;
        text-align: center;
        padding: 25px 10px;
        font-size: 1.2em;
        min-height: 300px;
    }

    .step h4 {
        color: #ffd100;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .step h5 {
        width: 90%;
        margin: auto;
    }

    .check-button {
        background-color: #ffd100;
        border-radius: 8px;
        padding: 6px 25px 6px 25px;
        border: 0;
        color: white;
        font-weight: bold;
        margin-top: 20px;
        font-family: "Montserrat", sans-serif;
    }

    /* Services page */
    .service {
        padding: 120px 20px;
        text-align: center;
    }

    .service-container > div:first-of-type {
        padding-top: 120px;
    }

    .services-container > a:last-child {
        flex: 1 0 100%;
        margin-left: 0%;
        margin-right: 0%;
    }

    .service img {
        width: 90vw;
    }

    /* About us */
    .our-story {
        padding: 130px 20px 100px 20px;
    }
    .our-story img {
        width: 90vw;
        object-position: 77%;
    }

    .our-story p {
        margin-top: 50px;
    }

    .slider{
        margin-bottom: 50px;
    }

    .slider h2 {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

    .slider > div{
        margin: 50px 0px 80px 0px;
    }

    .our-clients{
        height: 200px;
    }

    .our-value {
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .our-value h3 {
        padding: 30px 10%;
    }

    .our-value img {
        width: 90vw;
    }

    .our-mission {
        padding-top: 38px;
        padding-bottom: 50px;
    }

    .our-vision {
        padding-top: 0px;
        padding-bottom: 100px;
    }

    /* Contact us */
    .contact-us-screen {
        gap: 5%;
        padding: 0px 20px;
    }

    .contact-us-screen p {
        line-height: 1.2;
    }

    .contact-us-info-details {
        flex-direction: column;
        gap: 50px;
        padding-top: 50px;
    }

    /* Footer */
    .footer {
        height: 150px;
        background-color: black;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        font-size: 0.8em;
        color: #ffd100;
        padding: 5px 0px;
    }

    .footer ul {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    .footer div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @keyframes whatsapp-slide-left{
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0%);
        } 
    }

    .whatsapp {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 50;
        animation: whatsapp-slide-left 1s ease-out forwards;
    }

    .whatsapp-link:active{
        background-color: rgba(50, 50, 50, 0.5);
    }
}
